<template>
	<div>
		<base-header class="header pt-6 pb-5 d-flex align-items-center">
			<!-- Mask -->
			<span class="mask bg-gradient-red opacity-8"></span>
			<!-- Header container -->
			<div class="container-fluid d-flex align-items-center">
				<div class="row">
					<div class="col-sm-12"></div>
				</div>
			</div>
		</base-header>
		<div class="container-fluid mt-5'">
			<div class="row mt-4">
				<div
					class="col-lg-4 col-md-3 col-sm-3 d-flex align-items-center justify-content-between"
				>
					<h2>Staff</h2>
				</div>
				<div class="col text-right">
					<base-button
						type="button"
						@click.prevent="handleAdd"
						class="btn btn-success"
					>
						Add Staff
					</base-button>
				</div>
			</div>
			<div class="card shadow mt-4 mb-5 p-3">
				<div class="row">
					<div class="col">
						<div class="table-responsive">
							<base-table
								class="table align-items-center table-flush"
								title=""
								thead-classes="thead-light"
								tbody-classes="list"
								type="hover"
								:data="pagedTableData"
							>
								<template slot="columns">
									<th>Sl No</th>
									<th
										v-for="(header, index) in tableHeaders"
										:key="index"
									>
										{{ header }}
									</th>
								</template>

								<template slot-scope="{ row, index }">
									<td>
										{{
											pageSize * (currentPage - 1) +
											(index + 1)
										}}
									</td>
									<td>{{ row.name }}</td>
									<td>{{ row.username }}</td>
									<td>{{ row.mobile }}</td>
									<td>{{ row.email }}</td>
									<td>
										<i
											class="btn fas fa-pen"
											@click.prevent="editUser(row)"
										></i>
										<i
											class="btn text-danger fas fa-trash"
											@click.prevent="deleteUser(row)"
										></i>
									</td>
									<td>
										<base-button
											type="button"
											@click.prevent="
												handlePermission(row)
											"
											class="btn btn-success btn-sm"
										>
											Edit Access
										</base-button>
									</td>
								</template>
							</base-table>
						</div>
					</div>
				</div>
				<base-pagination
					class="mt-3 mr-3"
					:perPage="pageSize"
					:total="tableData.length"
					v-model="currentPage"
					align="center"
				></base-pagination>
			</div>

			<!-- Permissions Modal -->

			<modal
				class="modal"
				:show="showModal"
				footerClasses="pt-0 pb-1"
				headerClasses="	"
				@close="showModal = false"
				modal-classes=" modal-dialog-centered  modal-md"
				type="mini"
			>
				<template v-slot:header>
					<div class="row">
						<h3 class="col-sm">Update Permission</h3>
					</div>
				</template>

				<div class="form-check mb-3">
					<input
						class="form-check-input"
						type="checkbox"
						v-model="permissionData.view_booking"
						:checked="permissionData.view_booking"
						id="flexCheckDefault"
					/>
					<label class="form-check-label" for="flexCheckDefault">
						View Booking 
					</label>
				</div>

				<div class="form-check mb-4">
					<input
						class="form-check-input"
						type="checkbox"
						v-model="permissionData.edit_booking"
						:checked="permissionData.edit_booking"
						id="flexCheckDefault2"
					/>
					<label class="form-check-label" for="flexCheckDefault2">
						Edit Booking 
					</label>
				</div>
				<div class="form-check mb-3">
					<input
						class="form-check-input"
						type="checkbox"
						v-model="permissionData.view_users"
						:checked="permissionData.view_users"
						id="flexCheckDefault5"
					/>
					<label class="form-check-label" for="flexCheckDefault5">
						View App User 
					</label>
				</div>

				<div class="form-check mb-4">
					<input
						class="form-check-input"
						type="checkbox"
						v-model="permissionData.edit_users"
						:checked="permissionData.edit_users"
						id="flexCheckDefault3"
					/>
					<label class="form-check-label" for="flexCheckDefault3">
						Edit App User 
					</label>
				</div>

				<template v-slot:footer>
					<base-button
						type="danger pl-4 pr-4 "
						class="mb-2 btn btn-sm"
						@click.prevent="showModal = false"
						>Cancel</base-button
					>
					<base-button
						type="success pl-4 pr-4"
						class="mb-2 btn btn-sm"
						@click.prevent="handleSavePermission"
						>Save</base-button
					>
				</template>
			</modal>

			<!-- Add Staff Modal -->
			<modal
				class="modal"
				:show="modalOpen"
				footerClasses="pt-0 pb-1"
				headerClasses="	"
				@close="modalOpen = false"
				modal-classes=" modal-dialog-centered  modal-lg"
				type="mini"
			>
				<template v-slot:header>
					<div class="row">
						<h3 class="col-sm">{{ modalText }}</h3>
					</div>
				</template>
				<div class="row">
					<div class="col-sm-6">
						<div class="mb-2">
							<label for="" class="">Name</label>
							<base-input
								placeholder="Name"
								class="input-group-alternative"
								alternative=""
								v-model="data.name"
							>
							</base-input>
						</div>
					</div>
					<div class="col-sm-6">
						<div class="mb-2">
							<label for="" class="">Username</label>
							<base-input
								placeholder="Username"
								class="input-group-alternative"
								alternative=""
								v-model="data.username"
							>
							</base-input>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-6">
						<div class="mb-2">
							<label for="" class="">Email</label>
							<base-input
								placeholder="Email"
								type="email"
								class="input-group-alternative"
								alternative=""
								v-model="data.email"
							>
							</base-input>
						</div>
					</div>
					<div class="col-sm-6">
						<div class="mb-2">
							<label for="" class="">Mobile</label>
							<base-input
								placeholder="Mobile"
								class="input-group-alternative"
								alternative=""
								v-model="data.mobile"
							>
							</base-input>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-6">
						<div class="mb-2">
							<label for="" class="">Password</label>
							<base-input
								placeholder="Password"
								class="input-group-alternative"
								alternative=""
								v-model="data.password"
							>
							</base-input>
						</div>
					</div>
					<div class="col-6">
						<div class="mb-3">
							<label for="" class="">Confirm Password</label>
							<base-input
								placeholder="Confirm Password"
								class="input-group-alternative"
								alternative=""
								v-model="data.confirm_password"
							>
							</base-input>
						</div>
					</div>
				</div>

				<template v-slot:footer>
					<base-button
						type="danger pl-4 pr-4 "
						class="mb-2 btn btn-sm"
						@click.prevent="modalOpen = false"
						>Cancel</base-button
					>
					<base-button
						type="success pl-4 pr-4"
						class="mb-2 btn btn-sm"
						@click.prevent="addUser"
						>Save</base-button
					>
				</template>
			</modal>
		</div>
	</div>
</template>
<script>
export default {
	name: "categories",
	data() {
		return {
			pageSize: 10,
			currentPage: 1,
			image: "./img/others.svg",
			tableHeaders: [
				"Name",
				"Username",
				"Mobile",
				"Email",
				"Actions",
				"Permissions",
			],
			modalOpen: false,
			showModal: false,
			modalText: "",
			data: {
				name: "",
				username: "",
				email: "",
				mobile: "",
				password: "",
				confirm_password: "",
			},
			staffID: null,
			permissionData: {
				view_booking: false,
				edit_booking: false,
			},
		};
	},
	computed: {
		tableData() {
			return this.$store.state.StaffData.tableData;
		},
		pagedTableData() {
			var start = (this.currentPage - 1) * this.pageSize;
			return this.tableData.slice(start, start + this.pageSize);
		},
	},
	methods: {
		fetchUserList() {
			this.$store.dispatch("fetchUserList").catch((err) => {
				this.$notify({
					title: "Failed to fetch user list",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: err.toString(),
				});
			});
		},
		handleAdd() {
			this.modalOpen = true;
			this.modalText = "Add User";
			this.data = {
				name: "",
				username: "",
				email: "",
				mobile: "",
				password: "",
				confirm_password: "",
			};
		},

		addUser() {
			console.log(this.data);
			var error = undefined;
			if (this.data.name == "") {
				error = "Name is required";
			} else if (this.data.username == "") {
				error = "Username is required";
			} else if (this.data.email == "") {
				error = "Email is required";
			} else if (this.data.mobile == "") {
				error = "Mobile is required";
			} else if (this.data.mobile && this.data.mobile.length > 10) {
				error = "Mobile number should be 10 digits";
			} else if (this.data.password == "") {
				error = "Password is required";
			} else if (this.data.confirm_password == "") {
				error = "Confirm Password is required";
			} else if (this.data.password != this.data.confirm_password) {
				error = "Password and Confirm Password must be same";
			} else if (this.data.password && this.data.password.length < 4) {
				error = "Password must be atleast 4 characters";
			}

			if (error) {
				this.$notify({
					title: error,
					icon: "fa fa-exclamation-triangle",
					type: "danger",
				});
				return;
			}

			this.$store
				.dispatch("setUserDetails", this.data)
				.then(() => {
					this.$notify({
						title: "User updated successfully",
						icon: "fa fa-check",
						type: "success",
					});
					this.modalOpen = false;
					this.fetchUserList();
				})
				.catch((error) => {
					this.$notify({
						title: "Failed to add user",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				});
		},
		deleteUser(item) {
			this.$store
				.dispatch("deleteUser", item)
				.then(() => {
					this.$notify({
						title: "User deleted successfully",
						icon: "fa fa-check",
						type: "success",
					});
					this.fetchUserList();
				})
				.catch((error) => {
					this.$notify({
						title: "Failed to delete user",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				});
		},

		editUser(item) {
			this.$store
				.dispatch("fetchUserDetails", item.id)
				.then(() => {
					this.data = JSON.parse(
						JSON.stringify(this.$store.state.StaffData.userDetails)
					);
				})
				.catch((err) => {
					this.$notify({
						title: "Failed to fetch user details",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString(),
					});
				});
			this.modalOpen = true;
			this.modalText = "Edit User";
		},

		handlePermission(item) {
			this.$store
				.dispatch("fetchStaffPermissionDetails", item.id)
				.then(() => {
					this.permissionData = JSON.parse(
						JSON.stringify(
							this.$store.state.StaffData.staffPermissionDetails
						)
					);
				})
				.catch((err) => {
					this.$notify({
						title: "Failed to fetch user details",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString(),
					});
				});
			this.showModal = true;
			this.staffID = item.id;
		},

		handleSavePermission() {
			this.$store
				.dispatch("setStaffPermissionDetails", {
					data: this.permissionData,
					staffID: this.staffID,
				})
				.then(() => {
					this.$notify({
						title: "Permission updated successfully",
						icon: "fa fa-check",
						type: "success",
					});
					this.showModal = false;
				})
				.catch((error) => {
					this.$notify({
						title: "Failed to update permission",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				});
		},
	},
	mounted() {
		this.fetchUserList();
	},
};
</script>
<style scoped>
.bg-dark {
	background-color: #002635;
}
</style>
