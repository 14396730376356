<template>
<div class="jumbo-page">
	<main class="admin-main">
		<!-- <div class="loader" :class="{'d-flex': displayLoader}" id="loader">
			<img src="../assets/loader.svg" alt="loader">
		</div> -->
		<div class="container-fluid">
			<div class="row">
				<div class="col-lg-5  bg-white">
					<div class="row align-items-center min-vh-100">
						<div class="mx-auto col-sm-8">
							<div class="p-2 text-center">
								<p>
									<!-- <img src="/img/brand/green.png" width="80" alt="Company Logo"> -->
								</p>
								<p class="admin-brand-content">
									Excellent Driver
								</p>
							</div>
							<h3 class="text-center p-2 pb-4 fw-400 text-uppercase">Admin Login</h3>
							<div class="alert alert-danger" :class="{'d-none': !showErrorMessage}" role="alert">
								{{ errorMessage }}
							</div>
							<div class="alert alert-warning" :class="{'d-none': !showCheckEmailMessage}" role="alert">
								Check your email for instructions to reset your password
							</div>
							<form class="needs-validation" id="loginform" method="POST" @submit.prevent="frmSubmit">
								<div class="form-row">
									<div class="form-group floating-label col-md-12">
										<!--<label>Username</label>-->
										<input type="text" required class="form-control" placeholder="Username" name="username" v-model="username">
									</div>
									<div class="form-group floating-label col-md-12">
										<!--<label>Password</label>-->
										<input type="password" required class="form-control" placeholder="Password" name="password" v-model="password">
									</div>
								</div>

								<button type="submit" class="btn btn-primary btn-block btn-lg" id="login-button">Login</button>

							</form>
							<div class="text-right pt-1">
								<!-- <span @click.prevent="toggleResetPasswordDialog" class="text-underline small-font">Forgot Password?</span> -->

								<modal :show="showResetPasswordDialog" type="notice" @close="toggleResetPasswordDialog">
									<template v-slot:header>
										Reset password
									</template>
									<fieldset>
										<div class="pb-3 pt-2 pl-5 pr-5 text-center">Type in your email ID to reset your password</div>
										<input class="form-control input-lg" placeholder="Email ID" v-model="resetemail" id="email-textbox" name="email" type="email">
									</fieldset>
									<template v-slot:footer>
										<button class="btn btn-success" @click.prevent="resetPassword">Reset password</button>
									</template>
								</modal>
							</div>
						</div>

					</div>
				</div>
				<div class="col-lg-7 d-none d-md-block bg-cover image-box">
					<img src="https://img.freepik.com/free-photo/good-looking-business-woman-texting-message-while-walking-street_197531-6925.jpg?w=1380&t=st=1663048081~exp=1663048681~hmac=ebdbe26fc7972667269c77bc171e7c4c96b6609c1c22d4abd83f2458d633f4f7" alt="">

				</div>
			</div>
		</div>
	</main>
</div>
</template>
<script>
export default {
	name: 'login',
	data() {
		return {
			showErrorMessage: false,
			errorMessage: '',

			showResetPasswordDialog: false,
			showCheckEmailMessage: false,

			username: '',
			password: '',
			resetemail: ''
		}
	},
	methods: {
		toggleResetPasswordDialog() {
			this.resetemail = '';
			this.showResetPasswordDialog = !this.showResetPasswordDialog;
		},
		showError(error) {
			this.errorMessage = error;
			this.showErrorMessage = true;
		},

		async frmSubmit() {
			this.showCheckEmailMessage = false;
			this.showErrorMessage = false;

			const { username,password} = this
			this.$store.dispatch('login', {username,password})
			.then(() => {
				this.$router.push('/users');})
			.catch((error) => {
				this.showError(error);});

			return false;
		}
	}
}
</script>
<style scoped>
.text-underline {
	text-decoration: underline;
	cursor: pointer;
}

.small-font {
	font-size: 14px;
}

.image-box {
	background-image: url('https://www.deepflow.in/assets/images/bg.svg');
	background-size: cover;
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
}
</style>
